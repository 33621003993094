import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';


//import {GET_VEHICLETYPE, GET_ADDCAR }  from '../../actions'; 


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
  
const initialState = {
			vehicle_name:'',
			vehicle_price:'',
			vehicle_color:'',
			
			vehicle_model:'',			
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			image: [],
			isHidden: false,
			statusmessage:'',
			selectedVehicle:'',
			vehiclevalue:'',
			Loading:false,
			fileName: [],
			thumbDefault: null,
			priority: "",			
			
			
			admin_id: localStorage.getItem('admin_id'),
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No'
		};
		
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);

    }
	
	listloaddata=()=>{

		var qs = require('qs');
		axios.get(apiUrl+"vehicle/listdetail?id="+this.state.vehicleid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.vehiclelist;
				//this.setState({galleries:res.data.galleries});
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

					}
			// console.log(res.data.galleries,"res.data.galleriesres.data.galleries")
			           						
			           	this.setState({car_name:formdata.car_name});
						this.setState({car_colour:formdata.car_colour});			
			            this.setState({car_model:formdata.car_model});
						this.setState({car_price:formdata.car_price});
						this.setState({car_id: formdata.car_id});
						this.setState({priority:formdata.priority});
						this.setState({additional_charges : formdata.car_addition_charges});
			}	
			
		}); 
		

	}


	handleReset() {
         this.setState(initialState);
		 this.fileObj = [];
		 this.fileArray = [];
		 this.imageArray = [];
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Car Edit');
		this.listloaddata();
    }

  


onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  
uploadMultipleFiles = (e) =>{

		this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ user_image: this.imageArray });			
	
    }
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				
				var imgSize = '';
				if(formPayload.selectedsizevalue === ''){
				imgSize =formPayload.selectedsizeOption.value;
				}else{
				imgSize = formPayload.selectedsizevalue;
				}
                
                var galleryimg = 'No';
				if(Object.keys(formPayload.selectedimages).length >0){
                   galleryimg = 'Yes';
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					vehicle_name: formPayload.vehicle_name,
					vehicle_price:formPayload.vehicle_price,
					vehicle_color:formPayload.vehicle_color,
					vehicle_model: formPayload.vehicle_model,
					vehicle_image        : formPayload.image,
					vehicle_status      :status,
					image_size      :imgSize,
					thumbDefault :  formPayload.thumbDefault,
					priority        : formPayload.priority,
					uploadfromgallery : galleryimg					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
                
                var filesdata;
                if(galleryimg == 'No'){
                    filesdata = this.state.user_image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }
				
				
				for (var i in filesdata) {
				 formData.append('vehicle_image[]',filesdata[i])
				}	
							
			
				
				axios.post(apiUrl+"vehicle/edit",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   
						   console.log('success edit')
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {vehicle_name,vehicle_color,vehicle_price,vehicle_model,selectedVehicle,user_image} = this.state;
		let errors = 0;
	const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb
		if (!vehicle_name) {
			errors++;
			$('.errorvehicle_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_name){
			$('.errorvehicle_name').html('');
		}


		if (!vehicle_price) {
			errors++;
			$('.errorvehicle_price').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_price){
			$('.errorvehicle_price').html('');
		}
	
		if (!vehicle_model) {
			errors++;
			$('.errorvehicle_model').html('<span class="errorspan">Please fill the field</span>');
		}else if(vehicle_model){
			$('.errorvehicle_model').html('');
		}
		

		
		if(user_image.length == 0){
			errors++;
			$('.errorcarimage').html('<span class="errorspan">Please upload vehicle images</span>');
		}
		else if(user_image) {	 
			
	             for (let i = 0; i < user_image.length; i++) 
				 {

					const file = user_image[i];
					const filename = file.name;

					
					if (!allowedTypes.includes(file.type)) {
						errors++;
					
					$('.errorcarimage').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

					continue;
					}
	            }
	       }


		if(errors>0) { console.log('validation error')
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else { console.log('validation error no')
		return true;
		}

    }

    
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							if(thumbDefaultArr != null && thumbDefaultArr == index){
								indicheckFlag = true;
							}
						//}
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			var indicheckFlag = false;
		 				if(thumbDefaultArr != null && thumbDefaultArr == index){
							indicheckFlag = true;
						} 
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={carImageUrl+'/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }


 getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }


  render() {

  	
	let imagesrc = '';
	
  	const {selectedOption,selectedVehicle, user_image_preview} = this.state;

	

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="carlist" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Vehicle</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Vehicle Name:</label>
				    <input type="text" name="vehicle_name" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_name} />
					<div className="errorvehicle_name"></div>
				</div>
				
               <div className="form-group">
					<label>Vehicle Image:</label>

				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                    <span className="profile_btn">
				      <input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple />
					</span>
                </div>
				<div className="form-group">
				 <ul className="carimagelisting">
						
							{(this.state.galleryimg === 'No')?this.getImagesFrontUpload():this.getafteruploaded()}
						
					</ul>
					
				</div>
				
                
                <div className="errorcarimage"></div> 
                </div>
			


			{/*<div className="form-group">					
					<label>Image Size:</label>
						   <Select 
						   value={this.state.selectedsizeOption?this.state.selectedsizeOption:{ value: '350x350', label: '350x350'}}
                            options={lang.common.image_size_option} 
                            onChange={this.handlesizeChange}
                            />
			</div>*/}

				<div className="form-group">					
					<label>Basic Price:</label>
						<input type="text" name="vehicle_price" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_price} />
					<div className="errorvehicle_price"></div>
				</div>
				
				
			</div>				
			<div className="form-right">
			 
				<div className="form-group">
					<label>Model:</label>
						<input type="text" name="vehicle_model" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_model} />
					<div className="errorvehicle_model"></div>
				</div>
				
				<div className="form-group">
				  <label>Priority: </label>
				  <input type="text" name="priority" onChange={this.handleInputChange} className="form-group" value={this.state.priority} />
				</div>
				<div className="form-group">
					<label>Color:</label>
						<input type="text" name="vehicle_color" onChange={this.handleInputChange} className="form-control" value={this.state.vehicle_color} autoComplete="off" />
					<div className="errorvehicle_color"></div>
				</div>
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
		</div>	
	</div>
    </div>
    );
  }
}


export default (withRouter(Edit));
