 /*local */ 
// export const apiUrl      = "http://localhost/parkingslot/api/";
// export const baseUrl      = "http://localhost/parkingslot";
// export const mediaUrl      = "http://localhost/parkingslot/media/";
// export const carImageUrl      = "http://localhost/parkingslot/media/carImage/";
// export const sampleFileUrl      = "http://localhost/parkingslot/media/sample_file/";
// export const cmscontentURL       = "http://localhost/parkingslot/media/cmscontent";
// export const tapURL       = "http://localhost/parkingslot/media/tabmanagement";
// export const vehicleURL       = "http://localhost/parkingslot/media/vehicle";

/*Live */

export const apiUrl      = "https://en-admin.uxt.design/parkingslot/api/";
export const baseUrl      = "https://en-admin.uxt.design/parkingslot";
export const mediaUrl      = "https://en-admin.uxt.design/parkingslot/media/";
export const cmsURL       = "https://en-admin.uxt.design/parkingslot/media/cmsmanagement";
export const carImageUrl      = "https://en-admin.uxt.design/parkingslot/media/carImage/";
export const sampleFileUrl      = "https://en-admin.uxt.design/parkingslot/media/sample_file/";
export const cmscontentURL       = "https://en-admin.uxt.design/parkingslot/media/cmscontent"; 
export const categoryURL       = "https://en-admin.uxt.design/parkingslot/media/category";
export const tapURL       = "https://en-admin.uxt.design/parkingslot/media/tabmanagement"
export const vehicleURL       = "https://en-admin.uxt.design/parkingslot/parkingslot"

